// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-core-values-js": () => import("./../../../src/pages/core-values.js" /* webpackChunkName: "component---src-pages-core-values-js" */),
  "component---src-pages-design-tips-accessibility-mdx": () => import("./../../../src/pages/design-tips/accessibility.mdx" /* webpackChunkName: "component---src-pages-design-tips-accessibility-mdx" */),
  "component---src-pages-design-tips-colors-mdx": () => import("./../../../src/pages/design-tips/colors.mdx" /* webpackChunkName: "component---src-pages-design-tips-colors-mdx" */),
  "component---src-pages-design-tips-components-mdx": () => import("./../../../src/pages/design-tips/components.mdx" /* webpackChunkName: "component---src-pages-design-tips-components-mdx" */),
  "component---src-pages-design-tips-icons-mdx": () => import("./../../../src/pages/design-tips/icons.mdx" /* webpackChunkName: "component---src-pages-design-tips-icons-mdx" */),
  "component---src-pages-design-tips-index-mdx": () => import("./../../../src/pages/design-tips/index.mdx" /* webpackChunkName: "component---src-pages-design-tips-index-mdx" */),
  "component---src-pages-design-tips-layout-mdx": () => import("./../../../src/pages/design-tips/layout.mdx" /* webpackChunkName: "component---src-pages-design-tips-layout-mdx" */),
  "component---src-pages-design-tips-less-is-more-mdx": () => import("./../../../src/pages/design-tips/less-is-more.mdx" /* webpackChunkName: "component---src-pages-design-tips-less-is-more-mdx" */),
  "component---src-pages-design-tips-typography-mdx": () => import("./../../../src/pages/design-tips/typography.mdx" /* webpackChunkName: "component---src-pages-design-tips-typography-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-introduction-js": () => import("./../../../src/pages/introduction.js" /* webpackChunkName: "component---src-pages-introduction-js" */),
  "component---src-pages-principles-colors-js": () => import("./../../../src/pages/principles/colors.js" /* webpackChunkName: "component---src-pages-principles-colors-js" */),
  "component---src-pages-principles-index-js": () => import("./../../../src/pages/principles/index.js" /* webpackChunkName: "component---src-pages-principles-index-js" */),
  "component---src-pages-principles-typography-js": () => import("./../../../src/pages/principles/typography.js" /* webpackChunkName: "component---src-pages-principles-typography-js" */)
}

